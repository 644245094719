// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["iqAwW_5Pj"];

const variantClassNames = {iqAwW_5Pj: "framer-v-12ychfx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "iqAwW_5Pj", image: uml01sBZ3 = {src: new URL("assets/OMCuoNZRTHz3nmpTF2AEnZVE.png", import.meta.url).href, srcSet: `${new URL("assets/512/OMCuoNZRTHz3nmpTF2AEnZVE.png", import.meta.url).href} 328w, ${new URL("assets/1024/OMCuoNZRTHz3nmpTF2AEnZVE.png", import.meta.url).href} 657w, ${new URL("assets/OMCuoNZRTHz3nmpTF2AEnZVE.png", import.meta.url).href} 790w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "iqAwW_5Pj", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-NTXkW", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-12ychfx", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"iqAwW_5Pj"} ref={ref} style={{...style}} transition={transition}><Image background={{alt: "", fit: "fit", intrinsicHeight: 1230, intrinsicWidth: 790, pixelHeight: 1230, pixelWidth: 790, ...toResponsiveImage_194x2gw(uml01sBZ3)}} className={"framer-16kiwup"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"NqKBTikrQ"} style={{filter: "drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-NTXkW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NTXkW * { box-sizing: border-box; }", ".framer-NTXkW .framer-1maeyy6 { display: block; }", ".framer-NTXkW .framer-12ychfx { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 1080px; }", ".framer-NTXkW .framer-16kiwup { aspect-ratio: 0.6427221172022685 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 1587px); overflow: hidden; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-NTXkW .framer-12ychfx { gap: 0px; } .framer-NTXkW .framer-12ychfx > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-NTXkW .framer-12ychfx > :first-child { margin-left: 0px; } .framer-NTXkW .framer-12ychfx > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1647
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"uml01sBZ3":"image"}
 */
const FramerbFtcxQ5J2: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerbFtcxQ5J2;

FramerbFtcxQ5J2.displayName = "User flow comp 2";

FramerbFtcxQ5J2.defaultProps = {height: 1647, width: 1080};

addPropertyControls(FramerbFtcxQ5J2, {uml01sBZ3: {__defaultAssetReference: "data:framer/asset-reference,OMCuoNZRTHz3nmpTF2AEnZVE.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerbFtcxQ5J2, [])